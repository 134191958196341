.OrangeButton {
  font-family: "Montserrat";
  background: #e07d34 !important;
  color: white !important;
  border: 0px solid transparent;
  font-size: 14px;
  line-height: 17px;
  border-radius: 12px;
  font-weight: 700;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  padding-top: 15px;
  padding-bottom: 18px;
  padding-left: 15px;
  padding-right: 15px;
  text-decoration: none;
  text-align: center;
  display: flex;
  align-items: center;
  z-index: 3;
}
.OrangeButton:focus {
  caret-color: transparent;
}
@media (orientation: portrait),(max-width: 1050px) {
  .OrangeButton {
    padding-top: 7px;
    padding-bottom: 9px;
    padding-left: 7px;
    padding-right: 7px;
    font-size: 10px;
    line-height: 9px;
    border-radius: 6px;
  }
}
