.OpenJobs {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}
.OpenJobs > span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  color: #000000;
}

.OpenJobsItem {
  cursor: pointer;
  background: white;
  width: 30%;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100px;
  box-sizing: border-box;
  justify-content: space-between;
  border: 2px solid #fff;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  user-select: none;
}
.OpenJobsItem:hover {
  border: 2px solid #f48018;
}
.OpenJobsItem__hoverOnClick {
  border: 2px solid #f48018;
}
.OpenJobsItem__Text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  white-space: pre-line;
}
.OpenJobsItem img {
  height: 100%;
}
.OpenJobs__Group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 98%;
  justify-content: center;
  gap: 16px;
}
@media (orientation: portrait),(max-width: 1050px) {
  .OpenJobs__Group {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    justify-content: flex-start;
    align-items: stretch;
  }
  .OpenJobsItem {
    height: 60px;
    width: 100%;
  }
  .OpenJobsItem__Text {
    white-space: inherit;
    font-size: 14px;
    line-height: 17px;
  }
  .OpenJobs > span {
    font-size: 20px;
    line-height: 24px;
  }
}
