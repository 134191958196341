.OfferList {
  width: 100%;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.OfferList > span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  color: #000000;
}
.OfferList__Group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  z-index: 1;
  width: 100%;
  margin-top: 40px;
}
.OfferItem:nth-child(n + 4) {
  margin-bottom: 0;
}
.OfferItem__Img {
  width: 100px;
  margin-bottom: 20px;
}
.OfferItem span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  color: #000000;
}
.OfferItem {
  /* flex: 1 0 21%; */
  width: 30%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 79px;
}
@media (orientation: portrait),(max-width: 1050px) {
  .OfferList {
    margin-top: 20px;
  }
  .OfferList > span {
    font-size: 20px;
    line-height: 24px;
  }
  .OfferList__Group {
    margin-top: 20px;
  }
  .OfferItem {
    width: 40%;
    margin-bottom: 35px;
  }
  .OfferItem__Img {
    width: 50px;
  }
  .OfferItem span {
    font-size: 12px;
    line-height: 15px;
  }
}
