.Jobs {
  width: 100%;
  margin-top: 79px;
  gap: 30px;
  display: flex;
  flex-direction: column;
}
@media (orientation: portrait),(max-width: 1050px) {
  .Jobs {
    margin-top: 20px;
    gap: 10px;
  }
}
