.ConsultationRequest__Title > span {
  font-size: 40px;
  font-weight: 700;
}
.ConsultationRequest {
  margin-top: 124px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.ConsultationRequest__Title {
  text-align: left;
  margin-bottom: 30px;
}
.ConsultationRequest__Text__Coral {
  font-weight: 700;
  color: coral;
}
.ConsultationRequest__TextAndForm {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}
.ConsultationRequest__Text {
  font-weight: 300;
  font-size: 27px;
  line-height: 33px;
  text-align: left;
  /* margin-bottom: 30px; */
}
.ConsultationRequest__Form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 30%;
}
.ConsultationRequest__Form__Name {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  width: 35%;
  max-height: 60px;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.6rem;
  background: #e7e5e5;
  box-shadow: inset 0px 2px 7px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  outline: none;
  padding-top: 15px;
  padding-bottom: 18px;
  padding-left: 20px;
}
.ConsultationRequest__Form__Mail {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  width: 35%;
  max-height: 60px;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.6rem;
  background: #e7e5e5;
  box-shadow: inset 0px 2px 7px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  outline: none;
  padding-top: 15px;
  padding-bottom: 18px;
  padding-left: 20px;
}
.ConsultationRequest__Form__Mail::placeholder {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  color: #c4c4c4;
}
.ConsultationRequest__Form__Name::placeholder {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  color: #c4c4c4;
}
@media (orientation: portrait), (max-width: 1050px) {
  .ConsultationRequest__Title > span {
    font-size: 20px;
    line-height: 24px;
  }
  .ConsultationRequest {
    margin-top: 40px;
  }
  .ConsultationRequest__Title {
    margin-bottom: 0px;
  }
  .ConsultationRequest__Text__Coral {
  }

  .ConsultationRequest__TextAndForm {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .ConsultationRequest__Text {
    font-size: 14px;
    line-height: 17px;
    margin-top: 8px;
    margin-bottom: 0;
    margin-right: auto;
  }
  .ConsultationRequest__Form {
    flex-direction: column;
    justify-content: flex-start;
    width: 30%;
    align-items: stretch;
    margin-top: 8px;
  }
  .ConsultationRequest__Form__Name {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;
    font-size: 10px;
    line-height: 12px;
    width: auto;
    border-radius: 6px;
  }
  .ConsultationRequest__Form__Mail {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;
    font-size: 10px;
    line-height: 12px;
    width: auto;
    border-radius: 6px;
    margin-top: 8px;
  }
  .ConsultationRequest__Form__Button {
    font-size: 10px;
    line-height: 12px;
    margin-top: 8px;
    justify-content: center;
  }
  .ConsultationRequest__Form__Mail::placeholder {
  }
  .ConsultationRequest__Form__Name::placeholder {
  }
}
