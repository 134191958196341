*{
    margin: 0;
    padding: 0;
}

.privacy-container{
    /* background-color: grey; */
    width: 97vw;
    display: flex;
    justify-content: center;
    text-align: justify;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
}

.privacy-text{
    padding: 40px 80px;
    width: 40%;
    background-color: white;
}

.main-header{
    font-weight: bold;
    font-size: 22px;
    text-align: center;
    margin-bottom: 40px;
    margin-top: 20px;
}

h5{
    font-size: 18px;
    margin-top: 20px;
    text-align: center;
}

p{
    font-family: Trebuchet, sans-serif,Georgia, 'Times New Roman', Times, serif;
    font-size: 14px;
}

@media screen and (max-width: 900px) {
    .privacy-text{
        width: 85%;
    }
}

@media screen and (max-width: 500px) {
    .privacy-text{
        width: 90%;
        padding: 40px 20px;
    }
}
