.Header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 40px;
  padding-right: 40px;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding-top: 35px;
  z-index: 3;
}
.Header__Logo {
  height: 45px;
}
.Header__Nav {
}
.Header__Nav a {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
  color: #000000;
  text-decoration: none;
  padding-right: 76px;
}
.Header__Nav a:last-child {
  padding-right: 0px;
}
.Header__Nav__style-active {
  text-decoration-line: underline !important;
}

.Header__OrangeButton__noVisual {
  visibility: hidden;
}

@media screen and (max-width: 1050px) {
  .Header__Nav {
    display: none;
  }
  .Header {
    padding-left: 0;
    padding-right: 0;
    width: 95%;
    padding-top: 20px;
  }
  .Header__Logo {
    height: 24px;
  }
  .Header__OrangeButton {
    margin-left: auto;
    margin-right: 20px;
  }
}
@media screen and (max-width: 1050px) {
  .Header__Nav {
    display: none;
  }
}
