.leftSideBlow {
  position: absolute;
  top: 200px;
  left: -12%;
  z-index: 0;
  height: 700px;
  width: auto;
  transition: ease-in-out 1s;
}
.leftSideBlowFly {
  position: absolute;
  top: 270px;
  left: -20%;
  z-index: 0;
  height: 700px;
  width: auto;
  transition: ease-in-out 1s;
}
.RightSideBlow {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  z-index: 0;
  width: auto;
  height: 950px;
  transition: ease-in-out 1s;
}
.RightSideBlowFly {
  position: absolute;
  right: -40%;
  left: 50%;
  top: 10%;
  z-index: 0;
  width: auto;
  height: 950px;
  transition: ease-in-out 1s;
}
.hideblow {
  position: absolute;
  overflow: hidden;
  right: 0;
  top: 0;
  width: 320px;
  height: 1050px;
  z-index: 0;
}

@media (orientation: portrait), (max-width: 1050px) {
  .leftSideBlow {
    height: 300px;
    top: 300px;
    left: -32%;
  }
  .leftSideBlowFly {
    height: 300px;
    top: 350px;
    left: -45%;
  }
  .RightSideBlow {
    height: 400px;
    top: 84px;
    right: 0;
    left: 70%;
  }
  .RightSideBlowFly {
    height: 400px;
    top: 150px;
    right: 0;
    left: 90%;
  }

  .hideblow {
    max-height: 100vh;
  }
}
