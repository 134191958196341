.Portfolio {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 79px;
}
.PortfolioDescription {
  width: 80%;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  margin-top: 40px;
  margin-right: auto;
}
.PortfolioTitle {
  width: 100%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 49px;
  color: #000000;
}
@media screen and (orientation: portrait), (max-width: 1050px) {
  .Portfolio {
    margin-top: 20px;
  }
  .PortfolioDescription {
    font-size: 12px;
    line-height: 18px;
    margin-top: 20px;
    width: 100%;
  }
  .PortfolioTitle {
    font-size: 20px;
    line-height: 24px;
  }
}
