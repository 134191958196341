.cookie-alert-container {
  background-color: #282828;
  border-radius: 3px;
  bottom: 5px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  padding: 12px;
  position: fixed;
  right: 10px;
  transition: 0.3s;
  width: 98%;
  z-index: 9999;
  align-content: center;
  align-items: center;
  opacity: 0.9;
  border-radius: 4px;
}

.cookie-alertTextAndImg {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 15px;
  padding: 0 20px;
}

.cookie-alert-text {
  width: 100%;
  padding-bottom: 10px;
  color: #e7e7e7;
  font-family: "Montserrat";
}
.cookie-alert-button {
  background-color: #e07d3400;
  font-family: "Montserrat";
  font-size: 14px;
  color: white;
  border-radius: 3px;
  padding: 5px 10px;
  cursor: pointer;
  margin-left: auto;
  border-color: #e07d34;
}

.hiden {
  transform: translateX(927px);
}
@media (orientation: portrait), (max-width: 1050px) {
  .cookie-alert-container {
    width: 100%;
    right: 0;
    bottom: 0;
  }

  .cookie-alertTextAndImg {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding: 0 5px;
  }

  .cookie-alert-button {
    margin: 2%;
  }
  .cookie-alert-text {
    font-size: 13px;
  }
}
