.App {
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  position: relative;
}
.App__bacgroundToPrivacy {
  background-color: #979797;
}

.App__Actual {
  display: flex;
  margin-bottom: 80px;
  width: 1050px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
@media (orientation: landscape) {
  /* СКРОЛЛ */
  /* Opera и Chrome */
  ::-webkit-scrollbar-track {
    background-color: #f5f5f5; /*Цвет скролла*/
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 5px; /*Закругление ползунка*/
    background-color: #e07d34; /*Цвет ползунка*/
  }
  ::-webkit-scrollbar {
    width: 8px; /*Толщина скролла*/
  }
  /* FireFox */
  * {
    scrollbar-color: #e07d34 #f5f5f5; /*Цвет скролла, цвет ползунка*/
    scrollbar-width: thin; /*Толщина скролла thin, auto*/
  }
}
@media (orientation: portrait), (max-width: 1050px) {
  .App__Actual {
    width: 95%;
  }
  .App {
    position: relative;
  }
  .App:has(.ConsultationRowSign) {
    overflow: hidden;
  }
  .App:has(.ProjectsItem) {
    overflow: hidden;
  }
  .App:has(.Portfolio) {
    overflow: hidden;
  }
}
