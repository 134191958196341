.JobsText {
  width: 100%;
  z-index: 2;
}

.JobsText span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 49px;
  color: #000000;
}
.JobsText p {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  padding: 0;
  margin: 0;
  margin-top: 40px;
}
@media (orientation: portrait),(max-width: 1050px) {
  .JobsText {
  }
  
  .JobsText span {
    font-size: 20px;
line-height: 24px;
  }
  .JobsText p {
    font-size: 12px;
line-height: 18px;
margin-top: 20px;
  }
}