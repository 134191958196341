.ConsultationRowSign {
  padding-bottom: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.ConsultationRowSign__Info {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  position: relative;
  padding-top: 80px;
}
.ConsultationRowSign__Info__Text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 2;
  text-shadow: 1px 0px 2px rgba(0, 0, 0, 0.3);
  max-width: 55%;
}
.ConsultationRowSign__Info__Text__Big {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 49px;
  color: #000000;
}
.ConsultationRowSign__Info__Text__Small {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 27px;
  line-height: 33px;
  margin-top: 36px;
  color: #000000;
}
.ConsultationRowSign__Info__Text__Button {
  margin-top: 36px;
}
.ConsultationRowSign__Info__Gradient {
  display: block;
  width: 55%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  top: 0;
}
.ConsultationRowSign__Info__Gradient__Phone {
  width: 60%;
  z-index: 1;
  position: absolute;
  right: 0;
  top: 0;
}
@media (orientation: portrait),(max-width: 1050px) {
  .ConsultationRowSign__Info {
    padding-top: 35px;
  }
  .ConsultationRowSign__Info__Text__Big {
    font-size: 20px;
    line-height: 24px;
  }
  .ConsultationRowSign__Info__Text__Small {
    font-size: 14px;
    line-height: 17px;
    margin-top: 12px;
  }
  .ConsultationRowSign__Info__Text {
    max-width: 52%;
  }
  .ConsultationRowSign__Info__Text__Button {
    margin-top: 19px;
  }
  .ConsultationRowSign {
    padding-bottom: 0;
    max-width: 560px;
  }
}
