.AdvantagesGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 80px;
}
.AdvantagesGroup__Title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 49px;

  color: #000000;
}
.AdvantagesGroupItem {
  display: flex !important;
  flex-direction: column !important;
  max-width: 269px !important;
  align-items: center;
}
.AdvantagesGroupItem__img {
  width: 100px !important;
  height: 100px !important;
  text-align: center !important;
  z-index: 1;
}

.AdvantagesGroupItem__Title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  margin-top: 36px;
  font-size: 30px;
  line-height: 37px;
  text-align: center;
  color: #000000;
}
.AdvantagesGroupItem__Text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  margin-top: 28px;
}
.AdvantagesGroup__Items {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 60px;
  width: 100%;
}
@media (orientation: portrait) {
  .AdvantagesGroup {
    /* margin-top: 40px; */
  }
  .AdvantagesGroup__Title {
    font-size: 20px;
    line-height: 24px;
  }
  .AdvantagesGroupItem {
    max-width: 298px !important;
    margin-top: 20px;
  }
  .AdvantagesGroupItem:first-child {
    margin-top: 00px;
  }
  .AdvantagesGroupItem__img {
    width: 48px !important;
    height: 48px !important;
  }

  .AdvantagesGroupItem__Title {
    font-size: 16px;
    line-height: 20px;
    margin-top: 8px;
  }
  .AdvantagesGroupItem__Text {
    font-size: 12px;
    line-height: 15px;
    margin-top: 8px;
  }
  .AdvantagesGroup__Items {
    margin-top: 20px;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
  }
}
@media (max-width: 1050px) {
  .AdvantagesGroup {
    /* margin-top: 110px; */
  }
  .AdvantagesGroup__Title {
    font-size: 20px;
    line-height: 24px;
  }
  .AdvantagesGroupItem {
    max-width: 298px !important;
    margin-top: 20px;
  }
  .AdvantagesGroupItem:first-child {
    margin-top: 00px;
  }
  .AdvantagesGroupItem__img {
    width: 48px !important;
    height: 48px !important;
  }

  .AdvantagesGroupItem__Title {
    font-size: 16px;
    line-height: 20px;
    margin-top: 8px;
  }
  .AdvantagesGroupItem__Text {
    font-size: 12px;
    line-height: 15px;
    margin-top: 8px;
  }
  .AdvantagesGroup__Items {
    margin-top: 20px;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
  }
}
