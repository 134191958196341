/* Движение телефонов */
.jkh_phone1 {
  position: absolute;
  height: 293px;
  width: auto;
  z-index: 3;
  top: 60px;
  left: 0;
  transition: ease 3s;
}
.jkh_phone2 {
  position: absolute;
  height: 353px;
  width: auto;
  z-index: 2;
  top: 0;
  left: 85px;
  transition: ease 2s;
}
.jkh_phone3 {
  position: absolute;
  height: 333px;
  width: auto;
  z-index: 3;
  top: 20px;
  left: 125px;
  transition: ease 1s;
}
.jkh_phone1_fly {
  position: absolute;
  height: 293px;
  width: auto;
  z-index: 3;
  top: 60px;
  left: -50px;
  transition: ease 1s;
}
.jkh_phone2_fly {
  position: absolute;
  height: 353px;
  width: auto;
  z-index: 2;
  top: 0;
  left: 30px;
  transition: ease 1s;
}
.jkh_phone3_fly {
  position: absolute;
  height: 333px;
  width: auto;
  z-index: 3;
  top: 20px;
  left: 75px;
  transition: ease 1s;
}
.plz_group {
  position: relative;
  width: 40%;
}
.plz_phone1 {
  position: absolute;
  height: 453px;
  width: auto;
  z-index: 2;
  top: -20px;
  left: 80px;
  transition: ease 3s;
}
.plz_phone2 {
  position: absolute;
  height: 293px;
  width: auto;
  z-index: 3;
  top: 135px;
  left: 115px;
  transition: ease 2s;
}
.plz_phone3 {
  position: absolute;
  height: 303px;
  width: auto;
  z-index: 2;
  top: 80px;
  left: 180px;
  transition: ease 1s;
}
.plz_phone1_fly {
  position: absolute;
  height: 453px;
  width: auto;
  z-index: 2;
  top: -20px;
  left: 130px;
  transition: ease 1s;
}
.plz_phone2_fly {
  position: absolute;
  height: 293px;
  width: auto;
  z-index: 3;
  top: 135px;
  left: 165px;
  transition: ease 1s;
}
.plz_phone3_fly {
  position: absolute;
  height: 303px;
  width: auto;
  z-index: 2;
  top: 80px;
  left: 230px;
  transition: ease 1s;
}
.plz_phone {
  height: 483px;
  width: auto;
  margin: -50px -50px 10px 30px;
  z-index: 1;
  position: relative;
}
.ProjectCasesNew__Content__Group__Item__Images__Circle {
    position: absolute;
    height: 390px;
    width: auto;
    top: 78px;
    left: 106px;
  }
@media (orientation: portrait), (max-width: 1050px) {
    .ProjectCasesNew__Content__Group__Item__Images__Circle {
        top: 58px;
        left: 10px;
        height: 300px;
      }
    .ProjectCasesNew__Content__Group__Item__Images__Blob{
        height: 210px;
    }
    .ProjectCasesNew__Content__Group__Item__Images{
        height: 300px;
    }
  /* Движение телефонов */
  .jkh_phone1 {
    left: auto;
    margin-left: -30px;
    height: 200px;
  }
  .jkh_phone2 {
    left: auto;
    margin-left: 35px;
    height: 260px;
  }
  .jkh_phone3 {
    left: auto;
    margin-left: 95px;
    height: 243px;
  }
  .jkh_phone1_fly {
    left: auto;
    margin-left: -110px;
    height: 200px;
  }
  .jkh_phone2_fly {
    margin-left: -20px;
    left: auto;
    height: 260px;
  }
  .jkh_phone3_fly {
    left: auto;
    margin-left: 35px;
    height: 243px;
  }
  .plz_group {
  }
  /* Движение телефонов 2*/
  .plz_phone1_fly {
    left: auto;
    height: 340px;
    top: -20px;
    margin-left: -60px;
  }
  .plz_phone2_fly {
    left: auto;
    top: 105px;
    height: 210px;
    margin-left: -90px;
  }
  .plz_phone3_fly {
    left: auto;
    top: 40px;
    height: 270px;
    margin-left: 38px;
  }
  .plz_phone1 {
    left: auto;
    left: auto;height: 340px;
    top: -20px;
    margin-left: -40px;
  }
  .plz_phone2 {
    left: auto;
    top: 105px;height: 210px;;
    margin-left: -40px;
  }
  .plz_phone3 {
    left: auto;
    top: 40px;height: 270px;
    margin-left: 58px;
  }
  .plz_phone {
  }
}
