.PortfolioList {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 40px;
}
.PortfolioList a {
  text-decoration: none;
}
.PortfolioList__Item {
  width: 300px;
  height: 100%;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  filter: drop-shadow(0px 0px 30px rgba(230, 166, 63, 0.2));
  border-radius: 12px;
  cursor: pointer;
  margin-bottom: 30px;
}
.PortfolioList__Item__image {
  max-width: 100%;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  border-radius: 12px;
  width: 300px;
  height: 300px;
}
.PortfolioList__Item__description {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 23px;
  line-height: 29px;
  color: #000000;
  margin-top: 8px;
}
.PortfolioList a:nth-child(3n + 3) {
  margin-left: auto;
}
.PortfolioList a:first-child {
  margin-right: auto;
}
@media screen and (orientation: portrait) {
}
@media screen and (orientation: portrait), (max-width: 1050px) {
  .PortfolioList {
    margin-top: 20px;
    justify-content: space-between;
  }
  .PortfolioList__Item {
    width: 160px;
    margin-bottom: 20px;
  }
  .PortfolioList__Item:first-child {
    margin-right: 0;
  }
  .PortfolioList a:first-child {
    margin-right: 0;
  }
  .PortfolioList a:nth-child(3n + 3) {
    margin-left: 0;
  }
  .PortfolioList__Item__description {
    margin-top: 6px;
    font-size: 12px;
    line-height: 15px;
  }
  .PortfolioList__Item__image {
    width: 160px;
    height: 160px;
  }
}
