.UsingTechnologiesGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 98px;
  position: relative;
}
.UsingTechnologiesGroup__Background {
  background: linear-gradient(
    180deg,
    rgba(245, 163, 40, 0) 0%,
    rgba(245, 151, 26, 0.2) 50.31%,
    rgba(245, 164, 40, 0) 100%
  );
  position: absolute;
  height: 100%;
}
.UsingTechnologiesGroup__Text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 37px;
  text-align: center;
  color: #000000;
  z-index: 1;
  margin-top: 60px;
  /* padding: 0 12px; */
}
.UsingTechnologiesGroup__Items__Item {
  z-index: 1;
}

.UsingTechnologiesGroup__Items__Item__Icon {
  width: 195px;
  height: 195px;
  transition: ease-in-out 0.2s;
  box-sizing: border-box;
  filter: drop-shadow(0px 0px 50px rgba(34, 34, 34, 0.3));
}
.UsingTechnologiesGroup__Items__Item__Icon:hover {
  padding: 0px;
}
.UsingTechnologiesGroup__Items {
  margin-top: 61px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-bottom: 100px;
  justify-items: center;
  gap: 55px 200px;
}

@media (orientation: portrait), (max-width: 1050px) {
  .UsingTechnologiesGroup {
    margin-top: 60px;
  }
  .UsingTechnologiesGroup__Background {
  }
  .UsingTechnologiesGroup__Text {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    margin-top: 20px;
  }
  .UsingTechnologiesGroup__Items__Item {
  }

  .UsingTechnologiesGroup__Items__Item__Icon {
    width: 94px;
    height: 94px;
  }
  .UsingTechnologiesGroup__Items__Item__Icon:hover {
  }
  .UsingTechnologiesGroup__Items {
    gap: 36px;
  }
}
