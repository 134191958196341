.Footer {
  background: #1b1f3c !important;
  color: white !important;
  height: 60px !important;
  display: flex ;
  flex-direction: row-reverse ;
  width: 100%;
  margin-top: 104px;
  margin-top: auto;
  box-sizing: border-box;
  justify-content: space-around ;
  align-items: center ;
}

.Footer__Info {
  margin: auto;
  font-size: 14px;
}

.Footer__Contact {
  flex-direction: row;
  display: none;
  margin: auto;
  font-size: 14px;
}

.Footer__Contact > a {
  color: white !important;
  margin-right: 40px;
}

@media screen and (max-width: 600px) {
  .Footer__Contact {
    display: flex;
  }

  .Footer__Info {
    margin: 0 auto auto auto;
    font-size: 12px;
  }
}

@media (orientation: portrait) {
  .Footer {
    display: flex !important;
    flex-direction: column!important;
    justify-content: center!important;
    align-items: center!important;
  }
}
