.ProjectsItem {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 79px;
}
.ProjectsItem__image {
  position: absolute;
  top: 0;
  right: 0;
  width: 40%;
}
.ProjectsItem__MinTitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  color: #000000;
  margin-top: 40px;
  z-index: 1;
}
.ProjectsItem__Link {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-decoration: none;
  color: #000000;
  display: flex;
  align-items: center;
  text-align: center;
}
.ProjectsItem__Link img {
  width: 4px;
  padding-right: 16px;
  display: flex;
  align-items: flex-end;
  padding-top: 2px;
}
.ProjectsItem__about {
  width: 60%;
}
.ProjectsItem__title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 49px;
  margin-top: 20px;
  color: #000000;
  position: relative;
  width: 100%;
  white-space: pre-line;
}
.ProjectsItem__params {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  white-space: pre-line;
  margin-top: 20px;
  z-index: 1;
}
.ProjectsItem__technologies {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.ProjectsItem__technologies__Item {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  padding-right: 40px;
}
.ProjectsItem__technologies__Item:last-child {
  padding-right: 0px;
}
.ProjectsItem__technologies__Item__Title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.ProjectsItem__technologies__Item__Image {
  filter: drop-shadow(0px 0px 50px rgba(34, 98, 109, 0.3));
  border-radius: 12px;
  width: 60px;
  height: 60px;
}
.ProjectsItem__app {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
.ProjectsItem__app__image {
  width: 85%;
}
.ProjectsItem__app__title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  margin-top: 40px;
  color: #000000;
}
.ProjectsItem__app__items {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
}
.ProjectsItem__app__items a {
  text-decoration: none;
  /* padding-right: 24px; */
}
.ProjectsItem__app__items a:last-child {
  padding-right: 0px;
}
.ProjectsItem__app__items__image {
  width: 179px;
}
.ProjectsItem__image__Phone {
  display: none;
}
@media screen and (max-width: 600px) {
  .ProjectsItem__image__Phone {
    width: 100%;
    display: block;
    z-index: 1;
    margin-top: 20px;
  }
  .ProjectsItem__image {
    display: none;
  }

  .ProjectsItem__about {
    width: 100%;
  }
}
@media (orientation: portrait),(max-width: 1050px) {
  .ProjectsItem {
    margin-top: 20px;
  }
  .ProjectsItem__image {
    width: 20%;
  }

  .ProjectsItem__MinTitle {
    font-size: 14px;
    line-height: 17px;
    margin-top: 20px;
  }
  .ProjectsItem__Link {
    font-size: 12px;
    line-height: 15px;
  }
  .ProjectsItem__Link img {
  }
  .ProjectsItem__about {
  }
  .ProjectsItem__title {
    font-size: 20px;
    line-height: 24px;
    margin-top: 12px;
  }
  .ProjectsItem__params {
    font-size: 12px;
    line-height: 18px;
    margin-top: 12px;
  }
  .ProjectsItem__technologies {
  }
  .ProjectsItem__technologies__Item {
  }
  .ProjectsItem__technologies__Item:last-child {
  }
  .ProjectsItem__technologies__Item__Title {
    font-size: 12px;
    line-height: 15px;
  }
  .ProjectsItem__technologies__Item__Image {
    width: 30px;
    height: 30px;
    border-radius: 6px;
  }
  .ProjectsItem__app {
    margin-top: 12px;
  }
  .ProjectsItem__app__image {
    width: 140%;
  }
  .ProjectsItem__app__title {
    font-size: 12px;
    line-height: 15px;
    margin-top: 20px;
  }
  .ProjectsItem__app__items {
    margin-top: 8px;
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    gap: 10%;
    flex-wrap: wrap;
    justify-content: center;
    align-content: space-between;
  }
  .ProjectsItem__app__items a {
  }

  .ProjectsItem__app__items__image {
    width: 101px;
  }
}
