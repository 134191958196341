.react-multi-carousel-list {
  position: static !important;
  justify-content: center !important;
}
.react-multiple-carousel__arrow--right {
  right: calc(-5% + 1px) !important;
}
.react-multiple-carousel__arrow--left {
  left: calc(-5% + 1px) !important;
}
.react-multiple-carousel__arrow {
  background-color: #ffd5c0 !important;
}
.react-multiple-carousel__arrow::before {
  color: rgba(0, 0, 0, 0.35);
}
.react-multiple-carousel__arrow:hover {
  background: #ff6f20 !important;
  color: #ffffff !important;
}
.react-multi-carousel-item {
  z-index: 2;
  display: flex;
  justify-content: center;
}
.ClientFeedback {
  width: 110%;
  margin-top: 120px;
  position: relative;
  margin-bottom: 38px;
  min-height: 460px;
  user-select: none;
}

@media screen and (max-width: 820px) {
}
.ClientFeedback img {
  pointer-events: none !important;
}
.FeedBackItem {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 25%);
  border-radius: 20px;
  align-items: center;
  padding-bottom: 17px;
  margin-bottom: 15px;
  width: 350px;
  height: 468px;
  justify-content: space-between;
  background: linear-gradient(180deg, #f7dea7 0%, #f5a59c 100%);
}
.FeedBackItem__Header {
  height: 88px;
  width: 100%;
  border-top: 2px solid #ffffff;
  border-radius: 20px 20px 0px 0px;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.FeedBackItem__Header__image {
  height: 188px;
  object-fit: contain;
  position: absolute;
  top: 2px;
  width: 100%;
}
.FeedBackItem__Footer {
  padding-top: 58px;
  padding-left: 49px;
  padding-right: 49px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: calc(460px - 88px);
  justify-content: space-between;
}
.FeedBackItem__Footer__Aut {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
}
.FeedBackItem__Text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 22px;
  color: #000000;
}
.FeedBackItem__Icon {
  width: 60px;
}
.FeedBackItem__Name {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  color: #000000;
  margin-top: 9px;
}
.FeedBackItem__Post {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #ff6f20;
}
.FeedBackItem__FooterLine {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
}
.ClientFeedback__Bubble__Top {
  position: absolute;
  right: -3%;
  top: -7%;
}
.ClientFeedback__Bubble__Bottom {
  position: absolute;
  left: -3%;
  bottom: -7%;
}
@media screen and (max-width: 1300px) {
  .ClientFeedback {
    width: 100%;
    margin-top: 20px;
  }
  .ClientFeedback__Bubble__Top {
    position: absolute;
    right: -3%;
    top: -2%;
  }
  .react-multiple-carousel__arrow--right {
    right: 0 !important;
  }
  .react-multiple-carousel__arrow--left {
    left: 0 !important;
  }
}

@media (orientation: portrait) {
  .react-multi-carousel-list {
    position: static !important;
    justify-content: unset !important;
  }
}
