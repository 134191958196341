.WeOffer {
  margin-top: 124px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 50px;
}

.WeOffer__Form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 30%;
}

.WeOffer__Title {
  z-index: 2;
}

.WeOffer__Title > span {
  font-size: 40px;
  font-weight: 700;
  z-index: 2;
}

.WeOffer__Text {
  font-size: 20px;
  font-weight: 400;
  line-height: 33px;
  text-align: left;
}

.WeOffer__TextAndFormAndImage {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
}

.WeOffer__TextAndForm {
  gap: 24px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
  align-items: flex-start;
}

.WeOffer_image {
  width: 32%;
  z-index: 1;
  position: absolute;
  right: 0px;
  top: 13%;
}

.WeOffer_UnionAndText {
  display: flex;
  align-content: flex-start;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  gap: 10px;
}

.WeOffer_UnionAndText_Union {
  padding-top: 10px;
  z-index: 1;
  right: 0;
  top: 0;
}

@media (orientation: portrait) {
  .WeOffer_image {
    width: 51%;
    z-index: 0;
    position: absolute;
    right: 0px;
    top: 8% !important;
  }
  .WeOffer__Text {
    font-weight: 300;
    font-size: 15px;
    line-height: 26px;
    text-align: left;
  }
  .WeOffer__TextAndForm {
    gap: 5px;
  }
  .WeOffer__Title > span {
    font-size: 20px;
    line-height: 24px;
  }
}

@media (max-width: 1226px) {
  .WeOffer_image {
    top: 16%;
  }
}

@media (max-width: 1147px) {
  .WeOffer_image {
    top: 18%;
  }
}

