.RequestJob {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
  /* margin-left: 40px; */
}

.RequestJob__Block {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  height: 100%;
  align-items: flex-start;
  gap: 16px;
}

.RequestJob__Block__headers {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  gap: 16px;
}
.RequestJob__Block__Input {
  box-sizing: border-box;
  width: inherit;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.6rem;
  background: #e7e5e5;
  box-shadow: inset 0px 2px 7px rgb(0 0 0 / 25%);
  border-radius: 12px;
  outline: none;
  padding-top: 15px;
  padding-left: 17px;
  padding-bottom: 15px;
}
.RequestJob__Block__Name {
}

.RequestJob__Block__Phone {
}

.RequestJob__Block__Email {
}

.RequestJob__Block__Text {
  box-sizing: border-box;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  width: 75%;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.6rem;
  background: #e7e5e5;
  box-shadow: inset 0px 2px 7px rgb(0 0 0 / 25%);
  border-radius: 12px;
  outline: none;
  height: 100%;
  height: 140px;
  padding-top: 15px;
  padding-left: 17px;
  padding-bottom: 15px;
}

.RequestJob__Block__ButtonAndFile {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.RequestJob__Block__Button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.RequestJob__Block__Button__Style {
  padding-left: 37px;
  padding-right: 37px;
}

.RequestJob__Block__Input::placeholder {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  color: #c4c4c4;
}
.RequestJob__Block__Text::placeholder {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  color: #c4c4c4;
}
.input-file {
  position: relative;
  display: inline-block;
}
.input-file span {
  position: absolute;
  min-width: max-content;
  top: -20px;
  /* position: relative; */
  /* display: inline-block; */
  cursor: pointer;
  outline: none;
  text-decoration: none;
  font-size: 14px;
  /* vertical-align: middle; */
  color: #f47225;
  text-align: center;
  /* border-radius: 4px; */
  /* background-color: #419152; */
  line-height: 22px;
  height: 40px;
  padding: 10px 20px;
  box-sizing: border-box;
  border: none;
  /* margin: 0; */
  /* transition: background-color 0.2s;*/
}

.input-file__blockImgAndText {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  gap: 3px;
}

.input-file input[type="file"] {
  position: absolute;
  z-index: -1;
  opacity: 0;
  display: block;
  width: 0;
  height: 0;
}

/* Focus */
.input-file input[type="file"]:focus + span {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* Hover/active */
.input-file:hover span {
  color: #c15b20;
}
.input-file:active span {
  color: #f86f20;
}
@media (orientation: portrait), (max-width: 1050px) {
  .RequestJob__Block__Input {
    font-size: 10px;
    line-height: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;
    width: -webkit-fill-available;
    border-radius: 6px;
  }
  .input-file span {
    font-size: 10px;
  }
  .RequestJob__Block__headers {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    width: 100%;
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
  }

  .RequestJob__Block__Name {
  }
  .RequestJob__Block__Phone {
  }
  .RequestJob__Block__Email {
  }

  .RequestJob__Block__Text {
    font-size: 10px;
    line-height: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;
    width: -webkit-fill-available;
    border-radius: 6px;
  }
  .RequestJob__Block__Button__Style {
    font-size: 13px;
  }
}
