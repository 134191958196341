.ProjectCasesNew {
  width: 100%;
  margin-top: 100px;
  display: flex;
  align-items: center;
  flex-direction: row;
  z-index: 1;
}
.ProjectCasesNew__Content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ProjectCasesNew__Content > span {
  font-size: 40px;
  font-weight: 700;
  width: 100%;
}
.ProjectCasesNew__Content__Group {
  margin-top: 120px;
  display: flex;
  flex-direction: column;
}
.ProjectCasesNew__Content__Group__Item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.ProjectCasesNew_btn {
  margin-top: 100px;
}
.ProjectCasesNew__Content__Group__Item__Desk__Title {
  display: flex;
  align-items: center;
}
.ProjectCasesNew__Content__Group__Item__Desk__Title span {
  margin-left: 96px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 59px;
  text-align: center;

  color: #000000;
}
.ProjectCasesNew__Content__Group__Item__Images {
  position: relative;
  width: 40%;
}
.ProjectCasesNew__Content__Group__Item__Images__Blob {
  position: absolute;
  height: 303px;
  width: auto;
  z-index: 1;
  top: 140px;
  left: -34px;
}
.ProjectCasesNew__Content__Group__Item__Desk {
  display: flex;
  flex-direction: column;
  width: 60%;
  margin-top: 100px;
}
.ProjectCasesNew__Content__Group__Item__Desk p {
  text-align: start;
  padding: 0;
  margin: 0;
  margin-top: 28px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;

  color: #000000;
}
.ProjectCasesNew__Content__Group__Item__Desk_title {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}
.ProjectCasesNew__Content__Group__Item__Desk_title > img {
  width: 50px;
  height: 50px;
  margin: auto 60px auto 0;
}
.ProjectCasesNew__Content__Group__Item__Desk_title > span {
  font-size: 40px;
  font-weight: 700;
}
.ProjectCasesNew_icons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 28px;
}

.ProjectCasesNew_icons_and_button {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.ProjectCasesNew_icons a {
  margin-right: 20px;
}
.ProjectCasesNew_icons a:last-child {
  margin-right: 0px;
}
.ProjectCasesNew_link {
  width: 30px;
}
@media (orientation: portrait), (max-width: 1050px) {
  .ProjectCasesNew {
    margin-top: 40px;
  }
  .ProjectCasesNew__Content {
    width: 100%;
  }
  .ProjectCasesNew__Content > span {
    font-size: 20px;
    line-height: 24px;
  }
  .ProjectCasesNew__Content__Group {
    margin-top: 20px;
    width: 100%;
  }
  .ProjectCasesNew__Content__Group__Item {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }
  .ProjectCasesNew_btn {
    margin-top: 20px;
    max-width: 250px;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
  }
  .ProjectCasesNew__Content__Group__Item__Desk__Title {
    width: 100%;
  }
  .ProjectCasesNew__Content__Group__Item__Desk__Title img {
    width: 30px;
    height: 30px;
  }
  .ProjectCasesNew__Content__Group__Item__Desk__Title span {
    font-size: 16px;
    line-height: 20px;
    margin-left: 40px;
  }
  .ProjectCasesNew__Content__Group__Item__Images {
    height: 390px;
    display: flex;
    justify-content: center;
  }
  .ProjectCasesNew__Content__Group__Item__Images__Two {
    height: 420px;
    display: flex;
    justify-content: center;
  }
  .ProjectCasesNew__Content__Group__Item__Images__Blob {
    top: 78px;
    left: -116px;
  }
  .ProjectCasesNew__Content__Group__Item__Desk {
    width: 100%;
    margin-top: 20px;
  }
  .ProjectCasesNew__Content__Group__Item__Desk__Two {
    align-items: flex-end;
  }
  .ProjectCasesNew__Content__Group__Item__Desk > p {
    font-size: 12px;
    line-height: 15px;
    margin-top: 12px;
    width: 100%;
  }

  .ProjectCasesNew__Content__Group__Item__Desk__Two > p {
    text-align: end;
  }
  .ProjectCasesNew__Content__Group__Item__Desk_title {
  }
  .ProjectCasesNew__Content__Group__Item__Desk_title > img {
  }
  .ProjectCasesNew__Content__Group__Item__Desk_title > span {
  }
  .ProjectCasesNew_icons {
    margin-top: 12px;
    width: 100%;
  }

  .ProjectCasesNew_icons a {
  }
  .ProjectCasesNew_icons a:last-child {
  }
  .ProjectCasesNew_link {
    width: 20px;
    height: 20px;
  }
  .ProjectCasesNew__Content__Group__Item__Flipped {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
  }
}
