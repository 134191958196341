.MenuBar__Burger {
  display: none;
}
.MenuBar {
  display: none;
}
@media screen and (max-width: 1050px) {
  .MenuBar__Burger {
    display: block;
    cursor: pointer;
    box-shadow: 0px 0px 24px rgba(224, 125, 52, 0.25);
  }
  .MenuBar__Active__Header__Clouse {
    cursor: pointer;
    box-shadow: 0px 0px 24px rgba(224, 125, 52, 0.25);
  }
  .MenuBar__Active__Header__OrangeButton {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 10px;
    line-height: 12px;
    padding-top: 7px;
    padding-bottom: 9px;
    padding-left: 11px;
    padding-right: 11px;
  }
  .MenuBar {
    display: flex;
    position: fixed;
    background: #ffffff;
    width: 100%;
    left: 0;
    top: 0;
    padding-top: 0;
    width: 100%;
    box-sizing: border-box;
    justify-content: center;
  }
  .MenuBar__Active__Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    width: 100%;
  }
  .MenuBar__Active__Logo {
    height: 24px;
  }
  .MenuBar__Active {
    display: flex;
    width: 95%;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
  }
  .MenuBar__Nav {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    text-decoration: none;
    margin-top: 40px;
    color: #000000;
  }
  .MenuBar__Nav__style-active {
    text-decoration-line: underline;
  }
}
