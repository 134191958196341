.ContactsPage {
  margin-top: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.ContactsPage > span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 49px;
  color: #000000;
}

.OurContacts {
  margin-top: 40px;
  display: flex;
  width: 100%;
  z-index: 1;
}
.ContactsAtom__Info {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
}
.ContactsAtom__Info__Item {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.ContactsAtom__Info__Item a{
  display: flex;
    align-items: center;
    text-decoration: none;
}
.ContactsAtom__Info__Item:last-child {
  margin-bottom: 0px;
}
.ContactsAtom__Info__Item img {
  width: 30px;
  height: 30px;
}
.ContactsAtom__Title__Style {
  margin-top: 30px;
}
.ContactsAtom__Info__Item span {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  margin-left: 24px;
}

.ContactsAtom {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 250px;
}
.ContactsAtom__Title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;

  color: #000000;
}
.ContactsAtom span {
  text-align: start;
}

.RequestAtom {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
  margin-left: 40px;
}
.RequestAtom__Coral {
  color: coral !important;
}
.RequestAtom span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 27px;
  line-height: 33px;
  color: #000000;
}
.RequestAtom__Block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 100%;
  align-items: flex-start;
  margin-top: 30px;
}
.RequestAtom__Block__Name {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  width: 45%;

  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.6rem;
  background: #e7e5e5;
  box-shadow: inset 0px 2px 7px rgb(0 0 0 / 25%);
  border-radius: 12px;
  outline: none;
  padding-top: 15px;
  padding-left: 17px;
  padding-bottom: 15px;
}
.RequestAtom__Block__Phone {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  width: 45%;

  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.6rem;
  background: #e7e5e5;
  box-shadow: inset 0px 2px 7px rgb(0 0 0 / 25%);
  border-radius: 12px;
  outline: none;
  padding-top: 15px;
  padding-left: 17px;
  padding-bottom: 15px;
}
.RequestAtom__Block__Text {
  margin-top: 30px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  width: 100%;
  max-width: 760px;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.6rem;
  background: #e7e5e5;
  box-shadow: inset 0px 2px 7px rgb(0 0 0 / 25%);
  border-radius: 12px;
  outline: none;
  height: 100%;
  height: 140px;
  padding-top: 15px;
  padding-left: 17px;
  padding-bottom: 15px;
}
.RequestAtom__Block__Input::placeholder {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  color: #c4c4c4;
}
.RequestAtom__Block__Button {
  margin-top: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.RequestAtom__Block__Button__Style {
  padding-left: 37px;
  padding-right: 37px;
}
.MapAtom {
  margin-top: 40px;
  width: 100%;
  box-shadow: inset 0px 6px 4px rgba(0, 0, 0, 0.25);
}
@media (orientation: portrait),(max-width: 1050px) {
  .ContactsPage {
    margin-top: 20px;
  }
  .ContactsPage > span {
    font-size: 20px;
    line-height: 24px;
  }
  .OurContacts {
    margin-top: 20px;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
  .ContactsAtom__Info {
    margin-top: 8px;
  }
  .ContactsAtom__Info__Item {
    margin-bottom: 8px;
  }
  .ContactsAtom__Info__Item:last-child {
  }
  .ContactsAtom__Info__Item img {
    height: 20px;
    width: auto;
  }
  .ContactsAtom__Title__Style {
    margin-top: 12px;
  }
  .ContactsAtom__Info__Item span {
  }
  .ContactsAtom {
  }
  .ContactsAtom__Title {
  }
  .ContactsAtom span {
    font-size: 12px;
    line-height: 14px;
  }

  .RequestAtom {
    margin-left: 0;
  }
  .RequestAtom__Coral {
  }
  .RequestAtom span {
    font-size: 14px;
    line-height: 17px;
    margin-top: 40px;
  }
  .RequestAtom__Block {
    margin-top: 8px;
    flex-direction: column;
  }
  .RequestAtom__Block__Name {
    font-size: 10px;
    line-height: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;
    width: -webkit-fill-available;
    border-radius: 6px;
  }
  .RequestAtom__Block__Phone {
    width: -webkit-fill-available;
    font-size: 10px;
    line-height: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;
    border-radius: 6px;
    margin-top: 8px;
  }
  .RequestAtom__Block__Text {
    font-size: 10px;
    line-height: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;
    width: -webkit-fill-available;
    border-radius: 6px;
    margin-top: 8px;
  }
  .RequestAtom__Block__Input::placeholder {
  }
  .RequestAtom__Block__Button {
    margin-top: 8px;
  }
  .RequestAtom__Block__Button__Style {
  }
  .MapAtom {
  }
  .RequestAtom__Block__Button__Style {
    width: 100%;
    justify-content: center;
  }
}
