.ClientLine {
  margin-top: 233px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.ClientLine > span {
  font-size: 40px;
  font-weight: 700;
  width: 100%;
  text-align: center;
  max-width: 780px;
  margin: auto;
}
.ClientLine__Group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 36px;
}
.ClientLineItem {
  position: relative;
  max-height: 40px;
  margin: 40px;
}
.ClientLineItem__Back {
  cursor: pointer;
  position: relative;
  height: 100%;
  width: 100%;
  background-color: #f5f5f5;
  z-index: 2;
  transition: ease-in-out 0.2s;
}
.ClientLineItem__Color {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.ClientLineItem__Back:hover {
  opacity: 0;
  transition: ease-in-out 0.3s;
}
@media (orientation: portrait), (max-width: 1050px) {
  .ClientLine {
    margin-top: 40px;
  }
  .ClientLine > span {
    font-size: 20px;
    line-height: 24px;
  }
  .ClientLine__Group {
    flex-wrap: nowrap;
    margin-top: 20px;
    overflow: hidden;
    max-width: 100%;
    overflow-x: auto;

  }
  .ClientLine__Group::-webkit-scrollbar {
    width: 0 !important; 
    border-radius: 5px;
    background-color: transparent !important;
  }

  .ClientLineItem {
    margin: 0 !important;
    margin-right: 40px !important;
    height: 37px !important;
    width: 100% !important;
    max-height: fit-content;
  }
  .ClientLineItem__Back {
    width: auto;
    height: 100%;
    overflow: hidden;
  }
  .ClientLineItem__Color {
    width: auto;
    height: 100%;
  }
  .ClientLineItem__Back:hover {
  }
}
